import { useEffect, useState, useRef } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';
import { sendOrderData, getTurnData, setSelectedServices } from 'redux/app/action';

import { BASE_URL_AUTH, BASE_URL_API } from 'assets/js/baseUrl';
import Keyboard from 'components/Keyboard/Keyboard';
import { saveServicesList, saveTokens, saveDataFromToken, saveConfig } from 'redux/auth/action';
import './NameRegistration.scss';

const Login = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { selectedServices } = useSelector(state => state.appReducer);

	const subminButtonRef = useRef();

	const [name, setName] = useState([])

	useEffect(() => {
		if(Object.keys(selectedServices).length === 0) {
			history.push('/welcome')
		}

		document.title = 'Ввод имени | TOTO';
	}, [])

	const onSubmit = (event) => {
		event.preventDefault();

		dispatch(sendOrderData(selectedServices, history, subminButtonRef.current, name));
	}

	const getValueInput = (event) => {
		setName(event.target.value);
	}

	return (
		<div class='name-registration'>

			<h1>Введите ваше имя:</h1>

			<form onSubmit={onSubmit} method='post' autoComplete="off">
				<input
					onChange={getValueInput}
					value={name}
					type='text'
					name='name'
					placeholder='Сергей'
					maxLength={20}
					minLength={2}
				/>

				<button onClick={onSubmit} className='register-btn' disabled={name.length < 2} type='submit'>
					Зарегистрироваться
				</button>
			</form>

			<div 	ref={subminButtonRef} className="to_back" onClick={history.goBack}>{"<"} НАЗАД</div>
		</div>
	)
}

export default Login
