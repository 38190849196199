import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ErrorBoundary from 'components/ErrorBoundary';
import Login from 'pages/Login/Login';
import Welcome from 'pages/Welcome/Welcome';
import Phone from 'pages/Phone/Phone';
import GenderAge from 'pages/GenderAge/GenderAge';
import Services from 'pages/Services/Services';
import NameRegistration from 'pages/NameRegistration/NameRegistration';
import AfterOrder from 'pages/AfterOrder/AfterOrder';
import History from 'pages/History/History';
import Privacy from 'pages/Privacy/Privacy';
import Exit from 'pages/Exit/Exit';

import './App.scss';

const App = () => {
  return (
    <ErrorBoundary>
      <div className='container'>
        <Router basename='/'>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route path='/welcome' component={Welcome} />
            <Route path='/phone' component={Phone} />
            <Route path='/gender_age' component={GenderAge} />
            <Route path='/services' component={Services} />
            <Route path='/name' component={NameRegistration} />
            <Route path='/after_order' component={AfterOrder} />
            <Route path='/history' component={History} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/exit' component={Exit} />
          </Switch>
        </Router>
      </div>
      
      <ToastContainer />
    </ErrorBoundary>
  );
}

export default App;
