import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { setToHistoryRout, createEmptyOrder as createEmptyOrderApi, setSelectedServices } from 'redux/app/action';
import { checkToken } from 'redux/auth/action';
import './Welcome.scss';

const WelcomeStyledWrapper = styled.div`
	h1 {
		margin-top: ${({ deviceType }) => deviceType === 'tablet' ? '40px' : '50px'} ;
	}

	.to_services {
		margin-top: ${({ deviceType }) => deviceType === 'tablet' ? '60px' : '80px'};
	}

	.to_phone_history {
		margin: ${({ deviceType }) => deviceType === 'tablet' ? '60px 0 0 auto' : '100px 0 0 auto'};
	}
`;

const Welcome = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { config } = useSelector(state => state.authReducer);
	const { terminalFlowType, deviceType, disableDiscount } = config;

	useEffect(() => {
		checkToken();
		dispatch(setSelectedServices())
		document.title = 'Добро пожаловать в ТОТО!';
	}, [])

	const createEmptyOrder = () => {
		dispatch(createEmptyOrderApi())
			.then(() => history.push('/after_order'));
	}

	return (
		<WelcomeStyledWrapper deviceType={deviceType} id='welcome'>
			<h1>Добро пожаловать в ТОТО!</h1>

			{
				terminalFlowType === 2 ?
					<Button onClick={createEmptyOrder} className='to_phone' style={{ height: 100 }}>взять талон в очередь</Button>
					:
					disableDiscount ?
					<Link to='/services' className='to_phone'>ВЫБРАТЬ СТРИЖКУ</Link>
					:
					<>
						<p className='offer'><span>Чтобы каждая 3 стрижка стоила для Вас 6 рублей,</span><br /> войдите с помощью телефона</p>
						<Link to='/phone' className='to_phone'>ПРОДОЛЖИТЬ С ТЕЛЕФОНОМ</Link>
						<Link to='/services' className='to_services'>Продолжить без телефона</Link>
					</>
			}
			{disableDiscount ? '' : 
			<Link to='/phone' className='to_phone_history' onClick={() => dispatch(setToHistoryRout(true))}>посмотреть историю стрижек</Link>
			}
			{disableDiscount ? '' : 
				<Link to='/privacy' className='to_privacy'>Регистрируясь, вы соглашаетесь с условиями политики конфиденциальности</Link>
			}
		</WelcomeStyledWrapper >
	)
}

export default Welcome;
