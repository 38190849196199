import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { appClear } from 'redux/app/action';
import JapanWomen from 'assets/img/japanwomen.png';
import './AfterOrder.scss';

const AfterOrderStyledWrapper = styled.div`
	h1 {
		margin: ${({ deviceType }) => deviceType === 'tablet' ? '0 auto 33px' : '198px auto 33px'};
	}

	.ournumber {
		${({ deviceType }) => deviceType === 'tablet' ? '' : 'margin-top: 35px;'}
	}
`;

const AfterOrder = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { config } = useSelector(state => state.authReducer);
	const { deviceType } = config;
	const {
		forAfterOrderPageServId,
		forAfterOrderPageServName,
		forAfterOrderPageServPrice,
		turnNum,
		phone,
		balance,
		creative_haircut_balance,
		userName,
	} = useSelector(state => state.appReducer);

	const [timerId, setTimerId] = useState(null)

	const goToMainScreen = () => {
		clearTimeout(timerId)
		dispatch(appClear());
		history.push('/welcome');
	}

	useEffect(() => {
		setTimerId(setTimeout(() => {
			dispatch(appClear());
			history.push('/welcome');
		}, 15000))
		document.title = 'Спасибо за заказ | ТОТО';
	}, [dispatch, history])

	if (phone) {
		let lastBalance;
		let cutbrickList = [];

		if (forAfterOrderPageServId === 'balance') {
			lastBalance = balance
		} else {
			lastBalance = creative_haircut_balance;
		}

		for (let i = 0; i < 3; i++) {
			if ((lastBalance - i) > 0) {
				cutbrickList.push(<label>
					<input type="checkbox" name="" id="" defaultChecked />
					<div className="cutbrick"></div>
				</label>)
			} else {
				cutbrickList.push(<label>
					<input type="checkbox" name="" id="" />
					<div className="cutbrick"></div>
				</label>)
			}
		}

		return (
			<AfterOrderStyledWrapper deviceType={deviceType} id="after_order">
				<h1>До "{forAfterOrderPageServName.toLowerCase()}" за {forAfterOrderPageServPrice}  рубля осталось: {forAfterOrderPageServId === 1 ? 3 - balance : 3 - creative_haircut_balance} {lastBalance === 2 ? 'посещение' : 'посещения'}</h1>
				<div className="cutbrick_wrapper">{cutbrickList}</div>
				<div className="ournumber">Ваш номер <span className="turnnumber">{turnNum}</span></div>
				<img className="japanwomen" src={JapanWomen} alt="JapanWomen" />
			</AfterOrderStyledWrapper>
		)
	} else if(userName) {
		return (
			<AfterOrderStyledWrapper deviceType={deviceType} id="after_order">
				<div className="ournumber" ><span className="turnnumber">{userName}</span>, спасибо за регистрацию. </div>
				<p className="greetful">Ожидайте. Мастер скоро вас позовет!</p>
				<img className="japanwomen" src={JapanWomen} alt="JapanWomen" />

				<button className='main-btn' onClick={goToMainScreen}>На главный экран</button>
			</AfterOrderStyledWrapper>
		)
	} else {
		return (
			<AfterOrderStyledWrapper deviceType={deviceType} id="after_order">
				<div className="ournumber" >Ваш номер <span className="turnnumber">{turnNum}</span></div>
				<p className="greetful">Еще чуть-чуть и мастер вас позовет!</p>
				<p className="sps">Чек передайте мастеру!</p>
				<img className="japanwomen" src={JapanWomen} alt="JapanWomen" />
			</AfterOrderStyledWrapper>
		)
	}
}

export default AfterOrder
