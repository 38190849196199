import { useEffect, useState, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import $ from 'jquery';

import { BASE_URL_AUTH, BASE_URL_API } from 'assets/js/baseUrl';
import Keyboard from 'components/Keyboard/Keyboard';
import { saveServicesList, saveTokens, saveDataFromToken, saveConfig } from 'redux/auth/action';
import './Login.scss';

const Login = () => {
	const dispatch = useDispatch();
	const { accessToken } = useSelector(state => state.authReducer);

	const passwordInputRef = useRef();
	const loginInputRef = useRef();

	const [activeLogin, setActiveLogin] = useState(true);
	const [login, setLogin] = useState([]);
	const [password, setPassword] = useState([])

	useEffect(() => {
		document.title = 'Вход Терминал | TOTO';
	}, [])


	const onSubmit = (event) => {
		event.preventDefault();

		if (login.length < 4 || password.length < 4) return;

		$.ajax({
			type: 'POST',
			async: false,
			url: BASE_URL_AUTH + '/terminal/login',
			dataType: 'json',
			data: 'DATA=' + JSON.stringify({ login: login.join(''), password: password.join('') }),
			success: data => {
				dispatch(saveTokens(data));
				dispatch(saveDataFromToken(data.accessToken));

				$.ajax({
					type: 'POST',
					async: false,
					url: BASE_URL_API + '/terminal/get_services_list',
					dataType: 'json',
					data: 'DATA=' + JSON.stringify({ accessToken: data.accessToken }),
					success: data => {
						dispatch(saveServicesList(data.servicesList));
						dispatch(saveConfig(data));
					},
					error: (error) => {
						toast.error(`Ошибка соединения! \n ${JSON.stringify(error)}`);
					}
				});

			},
			error: (error) => {
				toast.error(`Неправильный логин или пароль! \n Попробуйте еще раз! \n ${JSON.stringify(error)}`);
			}
		});
	}

	const getKeyboardValue = (valueArr) => {
		if (activeLogin) {
			setLogin([...valueArr]);
		} else {
			setPassword([...valueArr]);
		}
	}

	const getValueInput = (event) => {
		if (activeLogin) {
			setLogin(event.target.value.split(''));
		} else {
			setPassword(event.target.value.split(''));
		}
	}

	const switchInputs = (event) => {
		if (event.currentTarget.name === 'login') {
			setActiveLogin(true);
			passwordInputRef.current.blur();
		} else {
			setActiveLogin(false);
			loginInputRef.current.blur();
		}
	}

	let styleActiveInput = { border: '3px solid red' };

	if (accessToken) return <Redirect to='welcome' />;

	return (
		<div class='login'>

			<h1>Введите данные для входа:</h1>

			<form onSubmit={onSubmit} method='post'>
				<input
					onChange={getValueInput}
					onClick={switchInputs}
					ref={loginInputRef}
					style={activeLogin ? styleActiveInput : {}}
					value={login.join('')}
					type='text'
					name='login'
					placeholder='введите код терминала'
					required
				/>
				<input
					onChange={getValueInput}
					onClick={switchInputs}
					ref={passwordInputRef}
					style={activeLogin ? {} : styleActiveInput}
					value={password.join('')}
					type='password'
					name='password'
					placeholder='введите пароль'
					required
				/>
				<button type='submit' hidden></button>
			</form>

			<div className='keyboardWrap'>
				<Keyboard callBack={getKeyboardValue} valuesArr={activeLogin ? login : password} />
			</div>

			<button onClick={onSubmit} class='loginbtn' type='submit'>ВОЙТИ (1.18.0)</button>

		</div>
	)
}

export default Login
